var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("div", { staticClass: "text-h4" }, [_vm._v("Dati del Simplo")]),
        _c("br"),
        _c("hr"),
        _c("br"),
        _c("br"),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.is_loading,
                expression: "!is_loading",
              },
            ],
            staticClass:
              "text-center justify-center q-pa-md q-gutter-sm fixed-center",
            staticStyle: { "z-index": "1000" },
          },
          [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
          1
        ),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-5 q-pr-md",
              attrs: { align: "left" },
            },
            [
              _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
                _vm._v("Dati generali pratica"),
              ]),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Cliente:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(
                              _vm._s(_vm.dati_pratica.nominativo.toUpperCase())
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Stato della pratica:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.stato_pratica)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Numero interno:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.numero_pratica)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Numero pratica:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.numero)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [
                            _vm._v("Unità operativa richiedente:"),
                          ]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.unita_operativa)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Persona operativa:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_pratica.operatore)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
                _vm._v("Dati della quietanza selezionata"),
              ]),
              _c(
                "q-list",
                { attrs: { separator: "" } },
                [
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Decorrenza:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_quietanze.decorrenza)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Scadenza:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_quietanze.scadenza)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Data di incasso:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_quietanze.data_incasso)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-item",
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Prodotto:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_quietanze.prodotto_id)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Fornitore:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_quietanze.fornitore)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "q-item-section",
                        [
                          _c("q-item-label", [_vm._v("Premio quietanza:")]),
                          _c("q-item-label", { attrs: { caption: "" } }, [
                            _vm._v(_vm._s(_vm.dati_quietanze.totale) + " Euro"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-6 q-pr-md",
              attrs: { align: "left" },
            },
            [
              _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
                _vm._v("Elenco dei Simpli associati alla Quietanza"),
              ]),
              _vm._l(_vm.lista_simpli.elenco, function (simplo, index) {
                return _c(
                  "q-list",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    key: index,
                    attrs: { clickable: "", separator: "", bordered: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onScaricaDocumentoDaUrl(
                          simplo.nome_file,
                          simplo.url
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "q-item",
                      { staticStyle: { cursor: "pointer" } },
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [_vm._v("Compagnia:")]),
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(_vm._s(simplo.compagnia.toUpperCase())),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [_vm._v("Prodotto:")]),
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(_vm._s(simplo.prodotto)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [_vm._v("Nome del File:")]),
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(_vm._s(simplo.nome_file)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          { attrs: { avatar: "" } },
                          [
                            _c("q-avatar", {
                              attrs: {
                                color: "orange-3",
                                "text-color": "black",
                                icon: "mdi-download",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "col-12 col-md-12 q-pr-md",
              attrs: { align: "center" },
            },
            [
              _c("QQButton", {
                attrs: {
                  label: "Indietro",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onIndietroClicked.apply(null, arguments)
                  },
                },
              }),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 com-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }